import React, { useState, useEffect } from 'react';
import { Calendar, ChevronDown, ChevronUp, Database, AlertCircle, Info } from 'lucide-react';
import apiClient from '../../utils/apiClient';
import { toast } from 'react-toastify';

const AdvancedSyncForm = ({ onSyncComplete, paid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trial, setTrial] = useState(0);
  const [syncData, setSyncData] = useState({
    propertyId: '',
    startDate: '',
    endDate: '',
    email: localStorage.getItem('databackfill-useremail')
  });
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [availableDimensions, setAvailableDimensions] = useState([]);
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [selectedDimensions, setSelectedDimensions] = useState([
    'eventName', 'date', 'platform', 'deviceCategory'
  ]);
  const [selectedMetrics, setSelectedMetrics] = useState([
    'eventCount', 'activeUsers', 'transactions'
  ]);
  const [presetConfigs, setPresetConfigs] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [currentScope, setCurrentScope] = useState('event');  // Default to event scope
  const [incompatibleWarnings, setIncompatibleWarnings] = useState([]);

  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate() - 60);
    const minDate = today.toISOString().split('T')[0];
    if (!paid) {
      document.getElementById('start-date')?.setAttribute('min', minDate);
    }

    // Calculate trial period
    const createdTime = localStorage.getItem('createdTime');
    if (createdTime) {
      const timestamp = parseInt(createdTime);
      const currentDate = new Date();
      const currentTimestamp = currentDate.getTime() / 1000;
      const differenceInSeconds = currentTimestamp - timestamp;
      const differenceInDays = Math.floor(differenceInSeconds / (60 * 60 * 24));
      setTrial(differenceInDays < 7 ? 7 - differenceInDays : 0);
    }

    // Fetch available dimensions and metrics
    
      fetchAvailableDimensionsMetrics();
      fetchPresetConfigurations();
    
  }, [paid]);

  const fetchAvailableDimensionsMetrics = async () => {
    try {
      const response = await apiClient.get('/api/dimensions-metrics');
      if (response && response.status === 'success') {
        setAvailableDimensions(response.data.dimensions || []);
        setAvailableMetrics(response.data.metrics || []);
      }
    } catch (error) {
      console.error('Error fetching dimensions and metrics:', error);
    }
  };

  const fetchPresetConfigurations = async () => {
    try {
      // These are our scope-based presets
      const presets = [
        {
          name: "Basic Event Analysis",
          dimensions: ["eventName", "date", "platform", "deviceCategory"],
          metrics: ["eventCount", "activeUsers", "screenPageViews"],
          scope: "event"
        },
        {
          name: "User Demographics",
          dimensions: ["firstUserSource", "firstUserMedium"],
          metrics: ["activeUsers", "newUsers", "totalUsers"],
          scope: "user"
        },
        {
          name: "Session Acquisition",
          dimensions: ["sessionSource", "sessionMedium", "sessionCampaignName"],
          metrics: ["sessions", "engagedSessions", "eventsPerSession"],
          scope: "session"
        },
        {
          name: "User Acquisition",
          dimensions: ["firstUserSource", "firstUserMedium", "firstUserCampaignName"],
          metrics: ["newUsers", "activeUsers", "totalRevenue"],
          scope: "user"
        },
        {
          name: "Content Performance",
          dimensions: ["pagePath", "pageTitle", "landingPage"],
          // Updated metrics to use only event-level metrics:
          metrics: ["screenPageViews", "eventCount"],
          scope: "event"
        },
        {
          name: "Event-based Ecommerce",
          dimensions: ["eventName", "date"],
          metrics: ["transactions", "purchaseRevenue", "eventCount"], // Removed itemsPurchased
          scope: "event"
        }
        ,
        {
          name: "Item-based Analysis",
          dimensions: ["itemName", "itemCategory", "itemBrand"],
          metrics: ["itemsViewed", "itemsAddedToCart", "itemsPurchased", "itemRevenue"],
          scope: "item"
        },
        {
          name: "Technical Performance",
          dimensions: ["deviceCategory", "operatingSystem", "browser"],
          // Removed 'engagementRate' because it’s session-level:
          metrics: ["screenPageViews"],
          scope: "event"
        },
        {
          name: "Conversion Funnel",
          dimensions: ["eventName", "deviceCategory"],
          // Removed 'totalUsers' since it’s user-level:
          metrics: ["eventCount", "conversions"],
          scope: "event"
        }
      ];
      
      
      setPresetConfigs(presets);
    } catch (error) {
      console.error('Error fetching preset configurations:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSyncData((prev) => ({ ...prev, [name]: value }));
  };

  // Helper function to determine a dimension's scope
  const getDimensionScope = (dimension) => {
    // These are rough scope mappings based on GA4's hierarchy
    const userDimensions = ["userAgeBracket", "userGender", "firstUserSource", "firstUserMedium", 
                          "firstUserCampaignName", "firstUserDefaultChannelGroup"];
    
    const sessionDimensions = ["sessionSource", "sessionMedium", "sessionCampaignName", 
                             "sessionDefaultChannelGroup", "newVsReturning"];
    
    const itemDimensions = ["itemId", "itemName", "itemCategory", "itemBrand", "itemVariant"];
    
    if (userDimensions.includes(dimension)) return "user";
    if (sessionDimensions.includes(dimension)) return "session";
    if (itemDimensions.includes(dimension)) return "item";
    return "event"; // Default to event scope (lowest level)
  };

  // Helper function to determine a metric's scope
  const getMetricScope = (metric) => {
    // These are rough scope mappings based on GA4's hierarchy
    const userMetrics = ["totalUsers", "newUsers", "averageRevenuePerUser", "userEngagementDuration"];
    
    const sessionMetrics = ["sessions", "engagedSessions", "engagementRate", 
                          "averageSessionDuration", "bounceRate", "sessionsPerUser"];
    
    const itemMetrics = ["itemsViewed", "itemsAddedToCart", "itemsPurchased", "itemRevenue", 
                        "cartToViewRate", "purchaseToViewRate"];
    
    if (userMetrics.includes(metric)) return "user";
    if (sessionMetrics.includes(metric)) return "session";
    if (itemMetrics.includes(metric)) return "item";
    return "event"; // Default to event scope
  };



  // Define dimension incompatibilities
const getDimensionIncompatibilities = () => {
  return {
    // Source/Medium combinations
    "sourceMedium": ["source", "medium"],
    "source": ["sourceMedium"],
    "medium": ["sourceMedium"],
    
    // Time dimensions
    "dateHour": ["date", "hour"],
    "date": ["dateHour"],
    "yearMonth": ["date", "month", "year", "yearWeek"],
    "yearWeek": ["date", "week", "year", "yearMonth"],
    "dateHourMinute": ["date", "hour", "minute", "dateHour"],
    
    // Page/Screen dimensions
    "fullPageUrl": ["hostName", "pagePath", "pagePathPlusQueryString"],
    "pagePathPlusQueryString": ["pagePath", "fullPageUrl"],
    "landingPage": ["pagePath", "landingPagePlusQueryString"],
    
    // User/Session scope conflicts
    "firstUserSource": ["sessionSource", "source"],
    "firstUserMedium": ["sessionMedium", "medium"],
    "firstUserCampaignName": ["sessionCampaignName", "campaignName"],
    "firstUserDefaultChannelGroup": ["sessionDefaultChannelGroup", "defaultChannelGroup"],
    
    // Channel grouping conflicts
    "defaultChannelGroup": ["primaryChannelGroup", "source", "medium", "sourceMedium"],
    "primaryChannelGroup": ["defaultChannelGroup"]
  };
};

// Check if adding a dimension would create an incompatible combination
const wouldCreateIncompatibleCombination = (newDimension, currentDimensions) => {
  // Don't check for dimensions already selected
  if (currentDimensions.includes(newDimension)) return false;
  
  // Get incompatibility map
  const incompatibilityMap = getDimensionIncompatibilities();
  
  // Check if the new dimension is incompatible with any current dimension
  for (const dim of currentDimensions) {
    // Check if either dimension has the other in its incompatibility list
    if (incompatibilityMap[newDimension]?.includes(dim) || 
        incompatibilityMap[dim]?.includes(newDimension)) {
      return true;
    }
  }
  
  // Check for scope mixing between user and session dimensions
  const isUserDimension = getDimensionScope(newDimension) === 'user';
  const isSessionDimension = getDimensionScope(newDimension) === 'session';
  
  const hasUserDimensions = currentDimensions.some(dim => getDimensionScope(dim) === 'user');
  const hasSessionDimensions = currentDimensions.some(dim => getDimensionScope(dim) === 'session');
  
  if ((isUserDimension && hasSessionDimensions) || (isSessionDimension && hasUserDimensions)) {
    return true;
  }
  
  return false;
};

// Check if a dimension is compatible with current selections
const isDimensionCompatible = (dimension, currentDimensions) => {
  if (currentDimensions.includes(dimension)) return true;
  if (dimension === 'eventName' || dimension === 'date') return true;
  return !wouldCreateIncompatibleCombination(dimension, currentDimensions);
};

  // Determine the highest scope in the current selection
  const determineCurrentScope = (dimensions) => {
    let highest = "event"; // Default to event scope (lowest)
    
    for (const dim of dimensions) {
      const scope = getDimensionScope(dim);
      if (scope === "user") {
        highest = "user"; // User is highest
        break;
      } else if (scope === "session" && highest !== "user") {
        highest = "session"; // Session is middle
      } else if (scope === "item" && highest === "event") {
        highest = "item"; // Item scope interacts differently
      }
    }
    
    return highest;
  };

  // Corrected function to determine scope compatibility
const isMetricCompatibleWithScope = (metric, scope) => {
  const metricScope = getMetricScope(metric);
  
  // Define scope hierarchy from highest to lowest
  const scopeHierarchy = ['user', 'session', 'event', 'item'];
  
  // Metric scope index must be greater than or equal to dimension scope index
  const dimensionScopeIndex = scopeHierarchy.indexOf(scope);
  const metricScopeIndex = scopeHierarchy.indexOf(metricScope);
  
  // If either scope is not found in our hierarchy, default to incompatible
  if (dimensionScopeIndex === -1 || metricScopeIndex === -1) return false;
  
  // Metric scope must be at same level or lower than highest dimension scope
  return metricScopeIndex >= dimensionScopeIndex;
};

  // Add this centralized function to your component
const updateCompatibilityWarnings = (dimensions, metrics) => {
  const scope = determineCurrentScope(dimensions);
  
  const warnings = [];
  const seenWarnings = new Set();
  
  metrics.forEach(metric => {
    if (!isMetricCompatibleWithScope(metric, scope)) {
      const warningText = `The metric "${metric}" may not be compatible with your selected dimensions due to GA4 scoping rules.`;
      if (!seenWarnings.has(warningText)) {
        warnings.push(warningText);
        seenWarnings.add(warningText);
      }
    }
  });
  
  setIncompatibleWarnings(warnings);
};

// Updated toggleDimension function
const toggleDimension = (dimension) => {
  setSelectedDimensions((prev) => {
    // Always keep eventName and date
    if (dimension === 'eventName' || dimension === 'date') return prev;
    
    // If dimension is already selected, remove it
    if (prev.includes(dimension)) {
      const newDimensions = prev.filter((d) => d !== dimension);
      
      // Update the current scope
      const newScope = determineCurrentScope(newDimensions);
      setCurrentScope(newScope);
      
      // Update compatibility warnings for metrics
      updateCompatibilityWarnings(newDimensions, selectedMetrics);
      
      return newDimensions;
    } 
    // If trying to add a dimension
    else {
      // Check if adding would exceed GA4 limit of 9 dimensions
      if (prev.length >= 9) {
        toast.warning('Maximum 9 dimensions can be selected (GA4 API limit)');
        return prev;
      }
      
      // Check if adding would create an incompatible combination
      if (wouldCreateIncompatibleCombination(dimension, prev)) {
        toast.warning(`"${dimension}" is incompatible with your current dimension selection`);
        return prev;
      }
      
      // Add the dimension
      const newDimensions = [...prev, dimension];
      
      // Update the current scope
      const newScope = determineCurrentScope(newDimensions);
      setCurrentScope(newScope);
      
      // Update compatibility warnings for metrics
      updateCompatibilityWarnings(newDimensions, selectedMetrics);
      
      return newDimensions;
    }
  });
  
  // Clear preset when manually changing dimensions
  setSelectedPreset(null);
};

const toggleMetric = (metric) => {
  setSelectedMetrics((prev) => {
    // Always keep eventCount
    if (metric === 'eventCount') return prev;
    
    // If metric is already selected, remove it
    if (prev.includes(metric)) {
      return prev.filter((m) => m !== metric);
    } 
    // If trying to add a metric
    else {
      // Check if metric is compatible with current dimension scope
      if (!isMetricCompatibleWithScope(metric, currentScope)) {
        toast.warning(`"${metric}" is not compatible with ${currentScope}-level dimensions`);
        return prev;
      }
      
      // Add the metric (limit to 10 metrics total)
      return [...prev, metric].slice(0, 10);
    }
  });

  // Clear preset when manually changing metrics
  setSelectedPreset(null);
};

const handlePresetChange = (e) => {
  const presetName = e.target.value;
  if (presetName === "") {
    setSelectedPreset(null);
    setIncompatibleWarnings([]);
    return;
  }
  
  const preset = presetConfigs.find(p => p.name === presetName);
  if (preset) {
    setSelectedPreset(presetName);
    
    // Set exactly the dimensions from the preset
    setSelectedDimensions(preset.dimensions);
    
    // For metrics, handle item-scoped presets specially
    const isItemPreset = preset.dimensions.some(dim => 
      ["itemName", "itemCategory", "itemBrand", "itemId", "itemVariant"].includes(dim)
    );
    
    // For item presets, use exactly the metrics from the preset
    // For other presets, make sure eventCount is included
    let metricsToUse = [...preset.metrics];
    if (!isItemPreset && !metricsToUse.includes('eventCount')) {
      metricsToUse.push('eventCount');
    }
    
    setSelectedMetrics(metricsToUse);
    setCurrentScope(preset.scope);
    setIncompatibleWarnings([]);
  }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Basic validation
      if (!syncData.propertyId.trim()) {
        toast.error('Property ID is required');
        setIsLoading(false);
        return;
      }

      if (!syncData.startDate || !syncData.endDate) {
        toast.error('Start and end dates are required');
        setIsLoading(false);
        return;
      }

      // If not using advanced mode, use standard backfill
      // Always use advanced sync with selected dimensions and metrics
const result = await apiClient.post('/api/advanced-backfill', {
  property_id: syncData.propertyId,
  start_date: syncData.startDate,
  end_date: syncData.endDate,
  email: syncData.email,
  dimensions: selectedDimensions,
  metrics: selectedMetrics
});

if (result.status === 'fill') {
  if (paid) {
    toast.error("You can't use more than 3 GA4 Properties");
  } else {
    toast.error("You can't use more than 1 GA4 Property");
  }
  setIsLoading(false);
  return;
}

toast.success('Data sync started successfully!');


      // Clear form
      setSyncData({
        propertyId: '',
        startDate: '',
        endDate: '',
        email: localStorage.getItem('databackfill-useremail')
      });

      // Notify parent component to refresh dashboard data
      if (onSyncComplete) {
        onSyncComplete();
      }
    } catch (error) {
      console.error('Sync error:', error);
      toast.error('Error starting data sync: ' + (error.message || 'Please check your selections for compatibility'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="flex items-center justify-between mb-6 md:mb-8">
        <div>
          <h1 className="text-xl md:text-2xl font-semibold text-gray-900">Data Sync</h1>
          <p className="mt-1 text-sm md:text-base text-gray-600">
            Configure your GA4 data sync settings
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        <form onSubmit={handleSubmit} className="p-4 md:p-6 space-y-6">
          {/* Basic Properties Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              GA4 Property ID
            </label>
            <input
              type="text"
              name="propertyId"
              value={syncData.propertyId}
              onChange={handleChange}
              className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                       focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
              placeholder="Enter your GA4 Property ID"
              required
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Start Date
              </label>
              <div className="relative">
                <input
                  id="start-date"
                  type="date"
                  name="startDate"
                  value={syncData.startDate}
                  onChange={handleChange}
                  className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                            focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base appearance-none"
                  required
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Calendar size={16} className="text-gray-500" />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                End Date
              </label>
              <div className="relative">
                <input
                  type="date"
                  name="endDate"
                  value={syncData.endDate}
                  onChange={handleChange}
                  className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                            focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base appearance-none"
                  required
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <Calendar size={16} className="text-gray-500" />
                </div>
              </div>
            </div>
          </div>



              {/* GA4 Scoping Information Panel */}
              {isInfoOpen && (
                <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-100">
                  <div className="flex items-start mb-2">
                    <Info size={18} className="text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
                    <h3 className="text-md font-medium text-blue-800">Understanding GA4 Scoping</h3>
                  </div>
                  
                  <p className="text-sm text-gray-700 mb-3">
                    GA4 data is organized in a hierarchical structure with different scope levels. To avoid double-counting and ensure accurate reporting:
                  </p>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-3">
                    <div className="flex items-start">
                      <div className="w-4 h-4 rounded-full bg-purple-500 mt-1 mr-2 flex-shrink-0"></div>
                      <div>
                        <p className="text-sm font-medium text-gray-800">User Scope (Highest)</p>
                        <p className="text-xs text-gray-600">Dimensions like userAgeBracket, firstUserSource</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="w-4 h-4 rounded-full bg-blue-500 mt-1 mr-2 flex-shrink-0"></div>
                      <div>
                        <p className="text-sm font-medium text-gray-800">Session Scope</p>
                        <p className="text-xs text-gray-600">Dimensions like sessionSource, sessionMedium</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="w-4 h-4 rounded-full bg-green-500 mt-1 mr-2 flex-shrink-0"></div>
                      <div>
                        <p className="text-sm font-medium text-gray-800">Event Scope</p>
                        <p className="text-xs text-gray-600">Dimensions like eventName, pagePath</p>
                      </div>
                    </div>
                    
                    <div className="flex items-start">
                      <div className="w-4 h-4 rounded-full bg-orange-500 mt-1 mr-2 flex-shrink-0"></div>
                      <div>
                        <p className="text-sm font-medium text-gray-800">Item Scope</p>
                        <p className="text-xs text-gray-600">Dimensions like itemName, itemCategory</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="text-sm bg-white p-3 rounded-md border border-blue-200">
                    <p className="font-medium text-gray-800 mb-1">The Golden Rule:</p>
                    <p className="text-gray-700 mb-2">
                      Use dimensions at one scope level, then use metrics at the same or lower scope level:
                    </p>
                    <ul className="list-disc pl-5 text-xs space-y-1 text-gray-600">
                      <li><span className="font-medium">With user dimensions</span>: You can use any metrics (user, session, event, item)</li>
                      <li><span className="font-medium">With session dimensions</span>: Use only session, event, or item metrics (not user metrics)</li>
                      <li><span className="font-medium">With event dimensions</span>: Use only event or item metrics (not user or session metrics)</li>
                      <li><span className="font-medium">With item dimensions</span>: Use only item metrics</li>
                    </ul>
                  </div>
                  
                  <p className="text-xs text-gray-500 mt-3">
                    Using our preset configurations ensures dimension and metric compatibility with the GA4 API.
                  </p>
                </div>
              )}

              {/* Advanced Options Expandable Panel */}
              <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
  <h3 className="text-md font-medium mb-4 text-gray-800">Advanced Dimensions & Metrics</h3>
    
                  {/* Preset Configuration Dropdown */}
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Preset Configurations <span className="text-green-600 text-xs">(GA4 scope compatible)</span>
                    </label>
                    <select
                      value={selectedPreset || ""}
                      onChange={handlePresetChange}
                      className="w-full p-2 border border-gray-300 rounded-lg focus-ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select a compatible preset (recommended)</option>
                      {presetConfigs.map(preset => (
                        <option key={preset.name} value={preset.name}>
                          {preset.name} ({preset.scope} scope)
                        </option>
                      ))}
                    </select>
                    <p className="mt-1 text-xs text-gray-500">
                      These presets ensure dimension and metric compatibility based on GA4's scope hierarchy.
                    </p>
                  </div>
                  
                  {/* Compatibility Warnings */}
                  {incompatibleWarnings.length > 0 && (
                    <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-md">
                      <div className="flex items-start">
                        <AlertCircle size={16} className="text-yellow-600 mt-0.5 mr-2 flex-shrink-0" />
                        <div>
                          <p className="text-sm font-medium text-yellow-700 mb-1">Potential Scoping Issues:</p>
                          <ul className="list-disc pl-5 text-xs text-yellow-700 space-y-1">
                            {incompatibleWarnings.map((warning, index) => (
                              <li key={index}>{warning}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* Dimensions Selection with Scope-Based Coloring */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="text-sm font-medium flex items-center">
                        <Database size={16} className="mr-1 text-blue-600" />
                        Dimensions
                      </h4>
                      <span className="text-xs text-gray-500">Current scope: <span className="font-semibold">{currentScope}</span></span>
                    </div>
                    
                    {/* Scope Legend */}
                    <div className="flex flex-wrap gap-2 mb-3">
                      <span className="inline-flex items-center text-xs px-2 py-1 rounded bg-purple-50 text-purple-700 border border-purple-200">
                        <div className="w-2 h-2 bg-purple-500 rounded-full mr-1"></div>User
                      </span>
                      <span className="inline-flex items-center text-xs px-2 py-1 rounded bg-blue-50 text-blue-700 border border-blue-200">
                        <div className="w-2 h-2 bg-blue-500 rounded-full mr-1"></div>Session
                      </span>
                      <span className="inline-flex items-center text-xs px-2 py-1 rounded bg-green-50 text-green-700 border border-green-200">
                        <div className="w-2 h-2 bg-green-500 rounded-full mr-1"></div>Event
                      </span>
                      <span className="inline-flex items-center text-xs px-2 py-1 rounded bg-orange-50 text-orange-700 border border-orange-200">
                        <div className="w-2 h-2 bg-orange-500 rounded-full mr-1"></div>Item
                      </span>
                    </div>
                    
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-2 max-h-48 overflow-y-auto p-2 bg-white rounded border border-gray-200">
  {availableDimensions.map((dimension) => {
    const scope = getDimensionScope(dimension);
    const isRequired = dimension === 'eventName' || dimension === 'date';
    const isCompatible = isDimensionCompatible(dimension, selectedDimensions);
    
    // Classes for different scopes
    const scopeClasses = {
      user: "text-purple-700 bg-purple-50 border-purple-200",
      session: "text-blue-700 bg-blue-50 border-blue-200",
      event: "text-green-700 bg-green-50 border-green-200",
      item: "text-orange-700 bg-orange-50 border-orange-200"
    };
    
    return (
      <div key={dimension} className="flex items-center">
        <input
          type="checkbox"
          id={`dim-${dimension}`}
          checked={selectedDimensions.includes(dimension)}
          onChange={() => toggleDimension(dimension)}
          disabled={isRequired || (!isCompatible && !selectedDimensions.includes(dimension))}
          className="mr-2"
        />
        <label
          htmlFor={`dim-${dimension}`}
          className={`text-sm truncate px-1 py-0.5 rounded border ${scopeClasses[scope]} ${
            isRequired ? 'font-medium' : ''
          } ${!isCompatible && !selectedDimensions.includes(dimension) ? 'opacity-50 cursor-not-allowed' : ''}`}
          title={!isCompatible && !selectedDimensions.includes(dimension) ? 
                 `Not compatible with current dimension selection` : ''}
        >
          {dimension} {isRequired && '(required)'}
          {!isCompatible && !selectedDimensions.includes(dimension) && ' ⚠️'}
        </label>
      </div>
    );
  })}
</div>
                  </div>

                  {/* Metrics Selection with Scope-Based Coloring */}
                  <div className="mb-6">
                    <h4 className="text-sm font-medium mb-2 flex items-center">
                      <Database size={16} className="mr-1 text-blue-600" />
                      Metrics
                      <span className="ml-1 text-xs text-gray-500">(eventCount is required)</span>
                    </h4>
                    
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-4 gap-y-2 max-h-48 overflow-y-auto p-2 bg-white rounded border border-gray-200">
  {availableMetrics.map((metric) => {
    const scope = getMetricScope(metric);
    const isRequired = metric === 'eventCount';
    
    // Is this metric compatible with current dimension scope?
    const isCompatible = isMetricCompatibleWithScope(metric, currentScope);
    
    // Classes for different scopes
    const scopeClasses = {
      user: "text-purple-700 bg-purple-50 border-purple-200",
      session: "text-blue-700 bg-blue-50 border-blue-200",
      event: "text-green-700 bg-green-50 border-green-200",
      item: "text-orange-700 bg-orange-50 border-orange-200"
    };
    
    return (
      <div key={metric} className="flex items-center">
        <input
          type="checkbox"
          id={`metric-${metric}`}
          checked={selectedMetrics.includes(metric)}
          onChange={() => toggleMetric(metric)}
          disabled={isRequired || (!isCompatible && !selectedMetrics.includes(metric))}
          className="mr-2"
        />
        <label
          htmlFor={`metric-${metric}`}
          className={`text-sm truncate px-1 py-0.5 rounded border ${scopeClasses[scope]} ${
            isRequired ? 'font-medium' : ''
          } ${!isCompatible && !selectedMetrics.includes(metric) ? 'opacity-50 cursor-not-allowed' : ''}`}
          title={!isCompatible && !selectedMetrics.includes(metric) ? 
                `Not compatible with ${currentScope} scope dimensions` : ''}
        >
          {metric} {isRequired && '(required)'}
          {!isCompatible && !selectedMetrics.includes(metric) && ' ⚠️'}
        </label>
      </div>
    );
  })}
</div>
                  </div>
                  
                  {/* Scoping Guidelines */}
                  <div className="mt-4 flex items-start bg-white p-3 rounded border border-blue-200">
                    <AlertCircle size={16} className="text-blue-600 mt-0.5 mr-2 shrink-0" />
                    <div className="text-xs text-gray-600">
                      <p className="font-medium text-gray-700 mb-1">GA4 Data Scoping Guidelines:</p>
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Not all dimensions and metrics can be combined in a single report</li>
                        <li>User-level metrics (purple) can <span className="text-red-600">inflate numbers</span> when used with session or event dimensions</li>
                        <li>When working with item-level dimensions (orange), use only item metrics</li>
                        <li>For best results, use preset configurations to avoid scoping issues</li>
                      </ul>
                    </div>
                  </div>
                  
                  {/* Current Selection Summary */}
                  <div className="mt-6 p-4 bg-white rounded-lg border border-gray-200">
                    <h4 className="text-sm font-medium mb-3">Current Selection Summary</h4>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <h5 className="text-xs font-medium text-gray-700 mb-2">Selected Dimensions:</h5>
                        <div className="flex flex-wrap gap-2">
                          {selectedDimensions.map(dim => {
                            const scope = getDimensionScope(dim);
                            const scopeColors = {
                              user: "bg-purple-50 text-purple-700 border-purple-200",
                              session: "bg-blue-50 text-blue-700 border-blue-200",
                              event: "bg-green-50 text-green-700 border-green-200",
                              item: "bg-orange-50 text-orange-700 border-orange-200"
                            };
                            
                            return (
                              <span 
                                key={dim} 
                                className={`inline-flex items-center text-xs px-2 py-1 rounded border ${scopeColors[scope]}`}
                              >
                                {dim}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      
                      <div>
                        <h5 className="text-xs font-medium text-gray-700 mb-2">Selected Metrics:</h5>
                        <div className="flex flex-wrap gap-2">
                          {selectedMetrics.map(metric => {
                            const scope = getMetricScope(metric);
                            const scopeColors = {
                              user: "bg-purple-50 text-purple-700 border-purple-200",
                              session: "bg-blue-50 text-blue-700 border-blue-200",
                              event: "bg-green-50 text-green-700 border-green-200",
                              item: "bg-orange-50 text-orange-700 border-orange-200"
                            };
                            
                            return (
                              <span 
                                key={metric} 
                                className={`inline-flex items-center text-xs px-2 py-1 rounded border ${scopeColors[scope]}`}
                              >
                                {metric}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            

          {/* Submit Button Section */}
          <div className="relative group">
            <button
              type="submit"
              disabled={isLoading || (!paid && !trial)}
              className={`w-full py-2 md:py-3 px-4 rounded-lg font-medium text-sm md:text-base
                      ${isLoading || (!paid && !trial)
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-600 hover:bg-blue-700 text-white'
                      } transition-colors`}
            >
              {isLoading ? 'Starting Sync...' : 'Start Sync'}
            </button>
            {!paid && !trial && (
              <div className="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm py-1 px-2 rounded">
                Upgrade to enable data syncing
              </div>
            )}
          </div>

          {/* Free Tier Notice */}
          {!paid && trial > 0 && (
            <div className="mt-4 bg-blue-50 p-3 rounded-lg text-xs text-gray-600 flex items-start">
              <AlertCircle size={16} className="text-blue-600 mt-0.5 mr-2 shrink-0" />
              <div>
                <p className="font-medium text-sm text-blue-800 mb-1">Free Tier Limitations:</p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>Limited to backfilling 60 days of historical data</li>
                  <li>Only one GA4 property allowed</li>
                  <li>Basic dimensions and metrics only</li>
                  <li>{trial} days remaining in your trial</li>
                </ul>
                <a href="/pricing" className="text-blue-600 hover:text-blue-800 font-medium mt-2 inline-block">
                  Upgrade for full access
                </a>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdvancedSyncForm;