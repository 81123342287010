import React, { useState } from 'react';
import { Search, ChevronRight, Shield, Lock, Mail, HelpCircle, ExternalLink, Menu, X, ChevronDown, Copy, CheckCircle2, Info, Database, AlertCircle } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

const DocumentationPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);
  const [copiedText, setCopiedText] = useState('');

  const sections = [
    {
      title: 'Getting Started',
      items: [
        { name: 'Introduction', id: 'introduction' },
        { name: 'How It Works', id: 'how-it-works' },
        { name: 'Prerequisites', id: 'prerequisites' },
      ]
    },
    {
      title: 'Configuration',
      items: [
        { name: 'GA4 Setup', id: 'ga4-setup' },
        { name: 'Google Cloud APIs', id: 'cloud-apis' },
        { name: 'IAM Roles', id: 'iam-roles' },
        { name: 'BigQuery Setup', id: 'bigquery-setup' },
        { name: 'Finding Project ID', id: 'finding-project-id' },
        { name: 'Finding Dataset ID', id: 'finding-dataset-id' },
        { name: 'Finding Table ID', id: 'finding-table-id' }
      ]
    },
    {
      title: 'Advanced Data Sync',
      items: [
        { name: 'Understanding GA4 Scoping', id: 'ga4-scoping' },
        { name: 'Dimensions & Metrics', id: 'dimensions-metrics' },
        { name: 'Preset Configurations', id: 'preset-configs' },
        { name: 'Compatibility Rules', id: 'compatibility-rules' }
      ]
    },
    {
      title: 'Security & Privacy',
      items: [
        { name: 'Data Flow & Storage', id: 'data-flow' },
        { name: 'Access Controls', id: 'access-controls' },
        { name: 'Security Best Practices', id: 'security-practices' }
      ]
    },
    {
      title: 'Using DataBackfill',
      items: [
        { name: 'Syncing Data', id: 'syncing-data' },
        { name: 'Monitoring Progress', id: 'monitoring-progress' },
        { name: 'Troubleshooting', id: 'troubleshooting' }
      ]
    }
  ];

  const handleCopySchema = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText('schema');
    setTimeout(() => setCopiedText(''), 2000);
  };

  const toggleSection = (index) => {
    if (expandedSection === index) {
      setExpandedSection(null);  // Close if already open
    } else {
      setExpandedSection(index);  // Open the clicked section
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      setIsSidebarOpen(false);
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const schemaText = `[
  {
    "name": "eventName",
    "type": "STRING",
    "mode": "REQUIRED",
    "description": "Name of the GA4 event"
  },
  {
    "name": "eventDate",
    "type": "STRING",
    "mode": "REQUIRED",
    "description": "Date when the event occurred (YYYYMMDD)"
  },
  {
    "name": "date",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Alternative date format (YYYYMMDD)"
  },
  {
    "name": "eventCount",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of times the event occurred"
  },
  {
    "name": "platform",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Platform (web, Android, iOS)"
  },
  {
    "name": "deviceCategory",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Device category (mobile, tablet, desktop)"
  },
  {
    "name": "browser",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Browser used (Chrome, Safari, etc.)"
  },
  {
    "name": "operatingSystem",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Operating system (Windows, macOS, Android, iOS)"
  },
  {
    "name": "country",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Country where the event originated"
  },
  {
    "name": "city",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "City where the event originated"
  },
  {
    "name": "region",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Region or state where the event originated"
  },
  {
    "name": "continent",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Continent where the event originated"
  },
  {
    "name": "language",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "User's language setting"
  },
  {
    "name": "deviceModel",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Device model information"
  },
  {
    "name": "mobileDeviceBranding",
    "type": "STRING", 
    "mode": "NULLABLE",
    "description": "Mobile device brand"
  },
  {
    "name": "screenResolution",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Screen resolution (e.g. 1920x1080)"
  },
  {
    "name": "sourceMedium",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Traffic source and medium (google / organic, direct / none)"
  },
  {
    "name": "source",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Traffic source (google, facebook, etc.)"
  },
  {
    "name": "medium",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Traffic medium (organic, cpc, email, etc.)"
  },
  {
    "name": "campaign",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Marketing campaign"
  },
  {
    "name": "campaignId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Marketing campaign ID"
  },
  {
    "name": "campaignName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Marketing campaign name"
  },
  {
    "name": "adContent",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Ad content description"
  },
  {
    "name": "keyword",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Search keyword"
  },
  {
    "name": "defaultChannelGroup",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Default channel grouping"
  },
  {
    "name": "sessionSource",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Source for the session"
  },
  {
    "name": "sessionMedium",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Medium for the session"
  },
  {
    "name": "sessionCampaignName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Campaign name for the session"
  },

  {
  "name": "sessionDefaultChannelGroup",
  "type": "STRING",
  "mode": "NULLABLE",
  "description": "Default channel group for the session"
},

  {
    "name": "pagePath",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Path portion of the page URL"
  },
  {
    "name": "pagePathPlusQueryString",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Path plus query string"
  },
  {
    "name": "pageTitle",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Title of the page"
  },
  {
    "name": "landingPage",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "First page in the session"
  },
  {
    "name": "exitPage",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Last page in the session"
  },
  {
    "name": "hostName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Website hostname"
  },
  {
    "name": "pageReferrer",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Referring URL"
  },
  {
    "name": "fullPageUrl",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Full page URL including protocol, hostname, path and query"
  },
  {
    "name": "itemId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Product ID"
  },
  {
    "name": "itemName",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Product name"
  },
  {
    "name": "itemBrand",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Product brand"
  },
  {
    "name": "itemCategory",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Product category"
  },
  {
    "name": "itemVariant",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Product variant"
  },
  {
    "name": "transactionId",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Transaction identifier"
  },
  {
    "name": "currencyCode",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Currency code for transaction (USD, EUR, etc.)"
  },
  {
    "name": "dayOfWeek",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Day of week (Monday, Tuesday, etc.)"
  },
  {
    "name": "hour",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Hour of day (0-23)"
  },
  {
    "name": "minute",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Minute of hour (0-59)"
  },
  {
    "name": "dateHour",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Date and hour combined (YYYYMMDDHH)"
  },
  {
    "name": "dateHourMinute",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Date, hour and minute combined (YYYYMMDDHHMM)"
  },
  {
    "name": "year",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Year (YYYY)"
  },
  {
    "name": "month",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Month (MM)"
  },
  {
    "name": "week",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Week number"
  },
  {
    "name": "yearMonth",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Year and month combined (YYYYMM)"
  },
  {
    "name": "yearWeek",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Year and week combined (YYYYWW)"
  },
  {
    "name": "newVsReturning",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Whether the user is new or returning"
  },
  {
    "name": "userAgeBracket",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "User age bracket"
  },
  {
    "name": "userGender",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "User gender"
  },
  {
    "name": "firstSessionDate",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Date of user's first session"
  },
  {
    "name": "page_view",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of page view events"
  },
  {
    "name": "session_start",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of session start events"
  },
  {
    "name": "user_engagement",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of user engagement events"
  },
  {
    "name": "first_visit",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of first visit events"
  },
  {
    "name": "scroll",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of scroll events"
  },
  {
    "name": "form_submit",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of form submission events"
  },
  {
    "name": "form_start",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of form start events"
  },
  {
    "name": "view_search_results",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of search result view events"
  },
  {
    "name": "click",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of click events"
  },
  {
    "name": "purchase",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of purchase events"
  },
  {
    "name": "add_to_cart",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of add to cart events"
  },
  {
    "name": "begin_checkout",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of begin checkout events"
  },
  {
    "name": "view_item",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of view item events"
  },
  {
    "name": "view_item_list",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of view item list events"
  },
  {
    "name": "view_promotion",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of view promotion events" 
  },
  {
    "name": "select_promotion",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of select promotion events"
  },
  {
    "name": "file_download",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of file download events"
  },
  {
    "name": "generate_lead",
    "type": "INTEGER", 
    "mode": "NULLABLE",
    "description": "Count of lead generation events"
  },
  {
    "name": "sign_up",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of sign up events"
  },
  {
    "name": "login",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of login events"
  },
  {
    "name": "activeUsers",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of active users"
  },
  {
    "name": "sessions",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of sessions"
  },
  {
    "name": "newUsers",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of new users"
  },
  {
    "name": "totalUsers",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Total number of users"
  },
  {
    "name": "screenPageViews",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of screen or page views"
  },
  {
    "name": "averageSessionDuration",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Average session duration in seconds"
  },
  {
    "name": "bounceRate",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Percentage of single-page sessions"
  },
  {
    "name": "engagementRate",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Percentage of engaged sessions"
  },
  {
    "name": "engagedSessions",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Count of engaged sessions"
  },
  {
    "name": "eventsPerSession",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Average number of events per session"
  },
  {
    "name": "screenPageViewsPerSession",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Average number of screen or page views per session"
  },
  {
    "name": "sessionsPerUser",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Average number of sessions per user"
  },
  {
    "name": "userEngagementDuration",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Total user engagement duration in seconds"
  },
  {
    "name": "transactions",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of transactions"
  },
  {
    "name": "purchaseRevenue",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Total purchase revenue"
  },
  {
    "name": "ecommercePurchases",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Total number of ecommerce purchases"
  },
  {
    "name": "itemsAddedToCart",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of items added to cart"
  },
  {
    "name": "itemsPurchased",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of items purchased"
  },
  {
    "name": "itemsViewed",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Number of items viewed"
  },
  {
    "name": "totalPurchasers",
    "type": "INTEGER",
    "mode": "NULLABLE",
    "description": "Total number of purchasers"
  },
  {
    "name": "itemRevenue",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Revenue from items"
  },
  {
    "name": "taxAmount",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Tax amount"
  },
  {
    "name": "shippingAmount",
    "type": "FLOAT",
    "mode": "NULLABLE",
    "description": "Shipping amount"
  },
  {
    "name": "Channel",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Marketing channel classification"
  },
  {
    "name": "Event_Type",
    "type": "STRING",
    "mode": "NULLABLE",
    "description": "Event type classification"
  },
  {
    "name": "Is_Conversion",
    "type": "BOOLEAN",
    "mode": "NULLABLE",
    "description": "Whether the event is a conversion"
  },
{
  "name": "conversions",
  "type": "FLOAT",  
  "mode": "NULLABLE",
  "description": "Number of conversions"
},
{
  "name": "firstUserSource",
  "type": "STRING",
  "mode": "NULLABLE",
  "description": "The first user source"
},
{
  "name": "firstUserMedium",
  "type": "STRING",
  "mode": "NULLABLE",
  "description": "The first user medium"
},
{
  "name": "firstUserCampaignName",
  "type": "STRING",
  "mode": "NULLABLE",
  "description": "The first user campaign name"
},
{
  "name": "totalRevenue",
  "type": "FLOAT",
  "mode": "NULLABLE",
  "description": "Total revenue from user acquisitions"
}
]`;

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <div className="pt-16 md:pt-20">
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-6 md:py-8">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Documentation</h1>
              <p className="mt-2 text-base md:text-lg text-gray-600">Everything you need to know about using DataBackfill</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
      <div className="lg:flex lg:gap-8">
  {/* Mobile Sidebar Toggle */}
  <button
    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
    className="lg:hidden w-full mb-4 flex items-center justify-between px-4 py-2 bg-white rounded-lg border border-gray-200"
  >
    <span className="font-medium text-gray-900">Documentation Menu</span>
    {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
  </button>

  {/* Side Navigation */}
  <div className={`
    lg:w-64 lg:block lg:shrink-0
    fixed lg:static inset-0 z-30 lg:z-0
    transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0
    transition-transform duration-300 ease-in-out
    bg-white lg:bg-transparent
    ${isSidebarOpen ? 'block' : 'hidden'}
  `}>
    {/* Mobile Overlay */}
    <div 
      className={`fixed inset-0 bg-black/20 lg:hidden ${isSidebarOpen ? 'block' : 'hidden'}`}
      onClick={() => setIsSidebarOpen(false)}
    />

    {/* Sidebar Content */}
    <div className="relative h-full lg:h-auto bg-white p-4 overflow-y-auto lg:sticky lg:top-28 rounded-lg border border-gray-200">
      <div className="space-y-6">
        {sections.map((section, index) => (
          <div key={index}>
            <button
              onClick={() => toggleSection(index)}
              className="flex items-center justify-between w-full text-left font-semibold text-gray-900 hover:text-blue-600"
            >
              <span>{section.title}</span>
              <ChevronDown 
                size={16} 
                className={`transform transition-transform ${expandedSection === index ? 'rotate-180' : ''}`}
              />
            </button>
            <div className={`mt-2 space-y-1 ${expandedSection === index ? 'block' : 'hidden'} lg:block`}>
              {section.items.map((item, itemIndex) => (
                <button
                  key={itemIndex}
                  onClick={() => scrollToSection(item.id)}
                  className="block w-full text-left pl-4 py-2 text-sm text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg"
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
          {/* Main Content */}
          <div className="flex-1 max-w-3xl">
            {/* Introduction */}
            <section id="introduction" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Introduction to DataBackfill</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                DataBackfill is a powerful tool designed to simplify the process of synchronizing your GA4 
                data with BigQuery. Whether you're looking to analyze historical data or maintain ongoing syncs, our platform 
                makes the process seamless and efficient.
              </p>
              <div className="bg-blue-50 rounded-lg p-6 mb-6">
                <img 
                  src="/dashboard-overview.png" 
                  alt="Dashboard Overview" 
                  className="rounded-lg mb-4 w-full"
                />
                <p className="text-sm text-gray-600">
                  The DataBackfill dashboard provides a clear overview of your sync status and history
                </p>
              </div>
            </section>

            {/* How It Works */}
            <section id="how-it-works" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">How It Works</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Your data flows directly from GA4 to your BigQuery instance - we never store your analytics data. 
                You maintain complete control through Google Cloud IAM, and can monitor or revoke access at any time.
              </p>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">1. Connect Your Accounts</h3>
                  <p className="text-gray-600">Grant access to your GA4 property and configure your BigQuery destination</p>
                </div>
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">2. Select Your Data Range</h3>
                  <p className="text-gray-600">Choose the time period for which you want to sync data</p>
                </div>
                <div className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">3. Start Syncing</h3>
                  <p className="text-gray-600">Monitor the progress as your data is transferred to BigQuery</p>
                </div>
              </div>
            </section>

            {/* Prerequisites */}
            <section id="prerequisites" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Prerequisites</h2>
              <p className="text-gray-600 mb-6">Before you begin, ensure you have:</p>
              <ul className="space-y-4">
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">GA4 Property Access</h3>
                  <p className="text-gray-600">Editor role access to your GA4 property</p>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">Google Cloud Project APIs</h3>
                  <p className="text-gray-600">A Google Cloud project with BigQuery API and Google Analytics Data API enabled</p>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">Required IAM Roles</h3>
                  <p className="text-gray-600">The following IAM roles in your Google Cloud project:</p>
                  <ul className="mt-2 ml-4 space-y-1 text-gray-600 text-sm">
                    <li>• BigQuery Data Editor (roles/bigquery.dataEditor)</li>
                    <li>• BigQuery Data Viewer (roles/bigquery.dataViewer)</li>
                    <li>• BigQuery Job User (roles/bigquery.jobUser)</li>
                    <li>• BigQuery User (roles/bigquery.user)</li>
                    <li>• Viewer (roles/viewer)</li>
                  </ul>
                </li>
                <li className="bg-white p-4 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-2">BigQuery Dataset</h3>
                  <p className="text-gray-600">A BigQuery dataset where your GA4 data will be stored</p>
                </li>
              </ul>
            </section>

            {/* GA4 Setup */}
            <section id="ga4-setup" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">GA4 Setup</h2>
              <p className="text-gray-600 mb-6">
                To enable DataBackfill to access your GA4 data, you'll need to grant the appropriate permissions:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Granting Editor Access</h3>
                  <ol className="space-y-4 text-gray-600">
                    <li>1. Open your GA4 property settings</li>
                    <li>2. Navigate to Admin → Property → Access Management</li>
                    <li>3. Click the "+" button to add a new user</li>
                    <li>4. Add your Google account email as an Editor (the same email you'll use to sign in to DataBackfill)</li>
                  </ol>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Finding Your Property ID</h3>
                  <img 
                    src="/ga4-property.png" 
                    alt="GA4 Property ID Location" 
                    className="rounded-lg mb-4 w-full"
                  />
                  <p className="text-gray-600">
                    Your GA4 Property ID can be found in the Admin section under Property Settings
                  </p>
                </div>
              </div>
            </section>

            {/* Google Cloud APIs Setup */}
            <section id="cloud-apis" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Google Cloud APIs Setup</h2>
              <p className="text-gray-600 mb-6">
                Enable the required APIs in your Google Cloud project:
              </p>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <h3 className="font-semibold mb-4">Enabling Required APIs</h3>
                <ol className="space-y-4 text-gray-600">
                  <li>1. Open your Google Cloud Console (console.cloud.google.com)</li>
                  <li>2. Navigate to API & Services</li>
                  <li>3. Click on "Enable APIs & Services"</li>
                  <li>4. Search for and enable:
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>• BigQuery API</li>
                      <li>• Google Analytics Data API</li>
                    </ul>
                  </li>
                </ol>
                <img 
                  src="/api-setup.png" 
                  alt="Google Cloud APIs Setup" 
                  className="rounded-lg mt-4 w-full"
                />
                <img 
                  src="/googleanalytics.png" 
                  alt="Google Cloud APIs Setup" 
                  className="rounded-lg mt-4 w-full"
                />
              </div>
            </section>

            {/* IAM Roles Section */}
            <section id="iam-roles" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">IAM Roles Setup</h2>
              <p className="text-gray-600 mb-6">
                Grant the necessary IAM roles in your Google Cloud project:
              </p>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <h3 className="font-semibold mb-4">Setting Up IAM Roles</h3>
                <ol className="space-y-4 text-gray-600">
                  <li>1. In Google Cloud Console, navigate to IAM & Admin</li>
                  <li>2. Select "IAM"</li>
                  <li>3. Click "Grant Access"</li>
                  <li>4. Add your email and assign these roles:
                    <ul className="ml-6 mt-2 space-y-2">
                      <li>• BigQuery Data Editor (roles/bigquery.dataEditor)</li>
                      <li>• BigQuery Data Viewer (roles/bigquery.dataViewer)</li>
                      <li>• BigQuery Job User (roles/bigquery.jobUser)</li>
                      <li>• BigQuery User (roles/bigquery.user)</li>
                      <li>• Viewer (roles/viewer)</li>
                    </ul>
                  </li>
                </ol>
                <img 
                  src="/iam-setup.png" 
                  alt="IAM Roles Setup" 
                  className="rounded-lg mt-4 w-full"
                />
              </div>
            </section>

            {/* BigQuery Setup */}
            <section id="bigquery-setup" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">BigQuery Setup</h2>
              <p className="text-gray-600 mb-6">
                Before syncing data, you'll need to set up your BigQuery environment with the correct schema:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="font-semibold">Table Schema</h3>
                    <button
                      onClick={() => handleCopySchema(schemaText)}
                      className="flex items-center text-blue-600 hover:text-blue-700"
                    >
                      {copiedText === 'schema' ? (
                        <CheckCircle2 size={20} className="mr-2" />
                      ) : (
                        <Copy size={20} className="mr-2" />
                      )}
                      {copiedText === 'schema' ? 'Copied!' : 'Copy Schema'}
                    </button>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg overflow-auto max-h-96">
                    <pre className="text-sm text-gray-800">{schemaText}</pre>
                  </div>
                </div>
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Creating Your Table</h3>
                  <ol className="mt-2 space-y-2 text-sm text-gray-600">
                    <li>1. Google Cloud Console → BigQuery</li>
                    <li>2. Select your dataset</li>
                    <li>3. Click "Create Table"</li>
                    <li>4. Under "Schema", click "Edit as text"</li>
                    <li>5. Paste the schema and save</li>
                  </ol>
                </div>

                <div className="bg-white p-6 rounded-lg border border-gray-200">
  <h3 className="font-semibold mb-4 text-lg">Understanding the Schema</h3>
  <div className="space-y-4">
    <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
      <h4 className="font-semibold text-blue-800 mb-2">Core Required Fields</h4>
      <ul className="space-y-2 text-sm text-blue-700 list-disc pl-5">
        <li><strong>eventName & eventCount</strong>: Core event metrics (required fields)</li>
        <li>Precisely identifies and quantifies individual events</li>
      </ul>
    </div>

    <div className="bg-green-50 p-4 rounded-lg border border-green-200">
      <h4 className="font-semibold text-green-800 mb-2">Event-Specific Counts</h4>
      <ul className="space-y-2 text-sm text-green-700 list-disc pl-5">
        <li><strong>Event-specific counts</strong>: Individual tracking for page views, sessions, etc.</li>
        <li>Captures detailed interactions across different event types:
          <ul className="pl-4 mt-1 list-disc text-xs">
            <li>Page views</li>
            <li>Session starts</li>
            <li>User engagements</li>
            <li>Form submissions</li>
          </ul>
        </li>
      </ul>
    </div>

    <div className="bg-purple-50 p-4 rounded-lg border border-purple-200">
      <h4 className="font-semibold text-purple-800 mb-2">Metadata and Classification</h4>
      <ul className="space-y-2 text-sm text-purple-700 list-disc pl-5">
        <li><strong>Metadata fields</strong>: Channel, Event Type, and conversion tracking</li>
        <li>Provides contextual insights:
          <ul className="pl-4 mt-1 list-disc text-xs">
            <li>Marketing channel classification</li>
            <li>Event type categorization</li>
            <li>Conversion event identification</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
              </div>
            </section>
            
            {/* Finding Your Project ID */}
<section id="finding-project-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your Google Cloud Project ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    Your Project ID is a unique identifier for your Google Cloud project. 
    It’s used when configuring your BigQuery dataset and allowing API access.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Project ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. Sign in to the <a href="https://console.cloud.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">Google Cloud Console</a>.</li>
      <li>2. In the top navigation bar, select the project dropdown.</li>
      <li>3. Find your project in the list. The <strong>Project ID</strong> is shown beneath the project’s name.</li>
      <li>4. Copy this value to use in DataBackfill’s <strong>Project ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-project-id.png" 
      alt="Project ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>

{/* Finding Your BigQuery Dataset ID */}
<section id="finding-dataset-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your BigQuery Dataset ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    The Dataset ID identifies the specific BigQuery dataset where your GA4 data 
    will be stored. You can find it in the BigQuery Console.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Dataset ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. In the Cloud Console, open the <strong>BigQuery</strong> section.</li>
      <li>2. In the left-hand navigation, expand your project to see your datasets.</li>
      <li>3. Right click on the <strong>Dataset</strong> and choose copy id. 
        It follows the format <em>projectName.datasetName</em>, but you only need the dataset portion (e.g., <em>myDataset</em>).</li>
      <li>4. Copy the dataset name to use in DataBackfill’s <strong>Dataset ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-dataset-id.png" 
      alt="Dataset ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>

{/* Finding Your BigQuery Table ID */}
<section id="finding-table-id" className="mb-12 scroll-mt-28">
  <h2 className="text-2xl font-bold text-gray-900 mb-4">Finding Your BigQuery Table ID</h2>
  <p className="text-gray-600 mb-6 leading-relaxed">
    The Table ID is the specific table in your BigQuery dataset that will store 
    the synchronized GA4 data.
  </p>
  <div className="bg-white p-6 rounded-lg border border-gray-200">
    <h3 className="font-semibold mb-4">Steps to Locate Your Table ID</h3>
    <ol className="space-y-4 text-gray-600">
      <li>1. In the BigQuery console, click on your dataset to expand its contents.</li>
      <li>2. Locate the table you want to use—this is your <strong>Table ID</strong>.</li>
      <li>3. Make sure you created the BigQuery table from the step aboved called BigQuery Setup. Then note its name (e.g., <em>events_table</em>).</li>
      <li>4. Use this table name in DataBackfill’s <strong>Table ID</strong> field.</li>
    </ol>
    <img 
      src="/placeholder-table-id.png" 
      alt="Table ID Location Screenshot"
      className="rounded-lg mt-4 w-full"
    />
  </div>
</section>
{/* New Advanced Data Sync Section */}
<section id="ga4-scoping" className="mb-12 scroll-mt-28">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Understanding GA4 Data Scoping</h2>
            <div className="space-y-6">
              <div className="bg-white rounded-lg border border-gray-200 p-6">
                <h3 className="font-semibold mb-4 text-lg">What is GA4 Scoping?</h3>
                <p className="text-gray-600 mb-4">
                  GA4 organizes data in a hierarchical structure with different scope levels. Understanding these 
                  levels is crucial for accurate reporting and avoiding data misinterpretation.
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-purple-50 p-4 rounded-lg border border-purple-200">
                    <div className="flex items-center mb-2">
                      <div className="w-6 h-6 bg-purple-500 rounded-full mr-3"></div>
                      <h4 className="font-semibold text-purple-800">User Scope (Highest)</h4>
                    </div>
                    <ul className="text-gray-700 text-sm space-y-1 pl-9">
                      <li>• User-level attributes</li>
                      <li>• First user acquisition data</li>
                      <li>• Demographic information</li>
                    </ul>
                  </div>

                  <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
                    <div className="flex items-center mb-2">
                      <div className="w-6 h-6 bg-blue-500 rounded-full mr-3"></div>
                      <h4 className="font-semibold text-blue-800">Session Scope</h4>
                    </div>
                    <ul className="text-gray-700 text-sm space-y-1 pl-9">
                      <li>• Session-level attributes</li>
                      <li>• Traffic source per session</li>
                      <li>• Engagement metrics</li>
                    </ul>
                  </div>

                  <div className="bg-green-50 p-4 rounded-lg border border-green-200">
                    <div className="flex items-center mb-2">
                      <div className="w-6 h-6 bg-green-500 rounded-full mr-3"></div>
                      <h4 className="font-semibold text-green-800">Event Scope</h4>
                    </div>
                    <ul className="text-gray-700 text-sm space-y-1 pl-9">
                      <li>• Individual event details</li>
                      <li>• Page-level information</li>
                      <li>• Device-specific data</li>
                    </ul>
                  </div>

                  <div className="bg-orange-50 p-4 rounded-lg border border-orange-200">
                    <div className="flex items-center mb-2">
                      <div className="w-6 h-6 bg-orange-500 rounded-full mr-3"></div>
                      <h4 className="font-semibold text-orange-800">Item Scope</h4>
                    </div>
                    <ul className="text-gray-700 text-sm space-y-1 pl-9">
                      <li>• Product-specific attributes</li>
                      <li>• E-commerce item details</li>
                      <li>• Conversion-related metrics</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-gray-200 p-6">
                <h3 className="font-semibold mb-4 text-lg">Scoping Rules</h3>
                <div className="text-gray-600 space-y-4">
                  <div className="flex items-start">
                    <Database className="w-5 h-5 mr-3 text-blue-600 mt-1 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-gray-800 mb-1">The Golden Rule of GA4 Dimensions & Metrics</p>
                      <p>
                        When selecting dimensions and metrics, follow this hierarchy:
                        <ul className="list-disc pl-5 mt-2 space-y-1">
                          <li>User-level dimensions can use any metrics</li>
                          <li>Session-level dimensions can use session and event metrics</li>
                          <li>Event-level dimensions can use only event and item metrics</li>
                          <li>Item-level dimensions can use only item metrics</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start bg-yellow-50 p-3 rounded-lg border border-yellow-200">
                    <Info className="w-5 h-5 mr-3 text-yellow-600 mt-1 flex-shrink-0" />
                    <p className="text-yellow-800">
                      Mixing dimensions from different scopes can lead to double-counting or inaccurate reporting. 
                      Always prioritize consistency in your data analysis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="dimensions-metrics" className="mb-12 scroll-mt-28">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Dimensions & Metrics</h2>
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="font-semibold mb-4 text-lg">Available Dimensions</h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                {[
                  "User Demographics", "Session Source", "Event Details", 
                  "Geographic Information", "Device Attributes", 
                  "Page Performance", "E-commerce Insights"
                ].map((category, index) => (
                  <div 
                    key={index} 
                    className="bg-gray-50 p-2 rounded text-center text-sm text-gray-700 hover:bg-blue-50 transition-colors"
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>
          </section>

                      <section id="preset-configs" className="mb-12 scroll-mt-28">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Preset Configurations</h2>
            <div className="bg-white rounded-lg border border-gray-200 p-6">
              <h3 className="font-semibold mb-4 text-lg">Recommended Analytics Configurations</h3>
              <div className="space-y-4">
                {[
                  {
                    name: "Basic Event Analysis",
                    description: "Understand overall event performance and user interactions",
                    dimensions: ["eventName", "date", "platform", "deviceCategory"],
                    metrics: ["eventCount", "activeUsers", "screenPageViews"],
                    scope: "Event",
                    icon: <Database className="w-6 h-6 text-green-600" />
                  },
                  {
                    name: "User Demographics",
                    description: "Analyze user characteristics and behavior",
                    dimensions: ["newVsReturning", "country", "city", "deviceCategory"],
                    metrics: ["activeUsers", "newUsers", "sessions"],
                    scope: "User",
                    icon: <Database className="w-6 h-6 text-purple-600" />
                  },
                  {
                    name: "Session Acquisition",
                    description: "Track how users are finding and engaging with your property",
                    dimensions: ["sessionSource", "sessionMedium", "sessionCampaignName"],
                    metrics: ["sessions", "engagedSessions", "eventsPerSession"],
                    scope: "Session",
                    icon: <Database className="w-6 h-6 text-blue-600" />
                  },
                  {
                    name: "User Acquisition",
                    description: "Understand how users first discover your property",
                    dimensions: ["firstUserSource", "firstUserMedium", "firstUserCampaignName"],
                    metrics: ["newUsers", "activeUsers", "totalRevenue"],
                    scope: "User",
                    icon: <Database className="w-6 h-6 text-purple-600" />
                  },
                  {
                    name: "Content Performance",
                    description: "Analyze page and content engagement",
                    dimensions: ["pagePath", "pageTitle", "landingPage"],
                    metrics: ["screenPageViews", "averageSessionDuration", "engagementRate"],
                    scope: "Event",
                    icon: <Database className="w-6 h-6 text-green-600" />
                  },
                  {
                    name: "Event E-commerce",
                    description: "Track transaction and purchase events",
                    dimensions: ["eventName", "date"],
                    metrics: ["transactions", "purchaseRevenue", "itemsPurchased"],
                    scope: "Event",
                    icon: <Database className="w-6 h-6 text-green-600" />
                  },
                  {
                    name: "Item Analysis",
                    description: "Deep dive into product performance",
                    dimensions: ["itemName", "itemCategory", "itemBrand"],
                    metrics: ["itemsViewed", "itemsAddedToCart", "itemsPurchased", "itemRevenue"],
                    scope: "Item",
                    icon: <Database className="w-6 h-6 text-orange-600" />
                  },
                  {
                    name: "Technical Performance",
                    description: "Understand technical user experience",
                    dimensions: ["deviceCategory", "operatingSystem", "browser"],
                    metrics: ["screenPageViews", "userEngagementDuration", "engagementRate"],
                    scope: "Event",
                    icon: <Database className="w-6 h-6 text-green-600" />
                  },
                  {
                    name: "Conversion Funnel",
                    description: "Track user conversion paths",
                    dimensions: ["eventName", "deviceCategory"],
                    metrics: ["eventCount", "totalUsers", "conversions"],
                    scope: "Event",
                    icon: <Database className="w-6 h-6 text-green-600" />
                  }
                ].map((config, index) => (
                  <div 
                    key={index} 
                    className="flex items-center bg-gray-50 p-4 rounded-lg border border-gray-200 hover:shadow-sm transition-shadow"
                  >
                    {config.icon}
                    <div className="ml-4 flex-1">
                      <div className="flex justify-between items-start">
                        <div>
                          <h4 className="font-semibold text-gray-800">{config.name}</h4>
                          <p className="text-sm text-gray-600 mb-2">{config.description}</p>
                        </div>
                        <span className="text-xs bg-blue-50 text-blue-600 px-2 py-0.5 rounded">
                          {config.scope} Scope
                        </span>
                      </div>
                      <div className="mt-2">
                        <div className="text-xs mb-1">
                          <span className="font-medium text-gray-700">Dimensions:</span>{' '}
                          <span className="text-gray-600">{config.dimensions.join(', ')}</span>
                        </div>
                        <div className="text-xs">
                          <span className="font-medium text-gray-700">Metrics:</span>{' '}
                          <span className="text-gray-600">{config.metrics.join(', ')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section id="compatibility-rules" className="mb-12 scroll-mt-28">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Compatibility Rules</h2>
            <div className="bg-white rounded-lg border border-gray-200 p-6 space-y-4">
              <div className="bg-blue-50 p-4 rounded-lg border border-blue-200 flex items-start">
                <Info className="w-6 h-6 mr-3 text-blue-600 flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-blue-800 mb-2">Key Compatibility Guidelines</h3>
                  <ul className="text-blue-700 text-sm space-y-2 list-disc pl-5">
                    <li>Maximum of 9 dimensions per query</li>
                    <li>Dimensions and metrics must be from compatible scopes</li>
                    <li>Some dimensions cannot be combined (e.g., sourceMedium with source)</li>
                    <li>Always include 'eventName' and 'date' in your queries</li>
                  </ul>
                </div>
              </div>

              <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-200 flex items-start">
                <AlertCircle className="w-6 h-6 mr-3 text-yellow-600 flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-yellow-800 mb-2">Common Pitfalls</h3>
                  <ul className="text-yellow-700 text-sm space-y-2 list-disc pl-5">
                    <li>Mixing user-level and session-level dimensions can cause double-counting</li>
                    <li>Using metrics from a higher scope than your dimensions</li>
                    <li>Selecting incompatible combination of dimensions</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

            {/* Security & Privacy Section */}
            <section id="data-flow" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Flow & Storage</h2>
              <p className="text-gray-600 mb-6">
                DataBackfill is designed with a security-first approach to protect your data. Understanding how your data flows is crucial:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">How Your Data Flows</h3>
                  <ol className="space-y-4 text-gray-600">
                    <li>1. Your data is read directly from your GA4 property</li>
                    <li>2. The data is transmitted securely to your BigQuery instance</li>
                    <li>3. We never store your analytics data on our servers</li>
                    <li>4. All data remains within your Google Cloud infrastructure</li>
                  </ol>
                </div>
                
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Data Storage Policy</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Your data resides exclusively in your BigQuery instance</span>
                    </li>
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Complete isolation from other customers' data</span>
                    </li>
                    <li className="flex items-start">
                      <Shield className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>No persistent storage of your analytics data on our infrastructure</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Access Controls Section */}
            <section id="access-controls" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Access Controls</h2>
              <p className="text-gray-600 mb-6">
                You maintain complete control over who can access your data through Google Cloud's IAM:
              </p>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Managing Access</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Control access through Google Cloud IAM permissions</span>
                    </li>
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Revoke access at any time via Google Cloud Console</span>
                    </li>
                    <li className="flex items-start">
                      <Lock className="w-5 h-5 text-blue-600 mt-1 mr-3 flex-shrink-0" />
                      <span>Monitor all data access through BigQuery audit logs</span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Security Best Practices Section */}
            <section id="security-practices" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Security Best Practices</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Recommended Security Settings</h3>
                  <ul className="space-y-4 text-gray-600">
                    <li>• Regularly review access permissions in Google Cloud Console</li>
                    <li>• Enable audit logging for your BigQuery instance</li>
                    <li>• Monitor data access patterns through BigQuery logs</li>
                  </ul>
                </div>
                
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Regular Security Audits</h3>
                  <p className="text-gray-600 mb-4">
                    We recommend performing regular security audits:
                  </p>
                  <ul className="space-y-2 text-gray-600">
                    <li>• Review IAM permissions monthly</li>
                    <li>• Check BigQuery audit logs for unusual activity</li>
                    <li>• Verify service account access permissions</li>
                    <li>• Update access credentials regularly</li>
                  </ul>
                </div>
              </div>
            </section>

            {/* Using DataBackfill Section */}
            <section id="syncing-data" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Using DataBackfill</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Starting a Sync</h3>
                  <ol className="space-y-2 text-gray-600">
                    <li>1. Enter your GA4 Property ID</li>
                    <li>2. Select your date range</li>
                    <li>3. Click "Start Sync" to begin the process</li>
                  </ol>
                </div>
              </div>
            </section>

            {/* Monitoring Progress Section */}
            <section id="monitoring-progress" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Monitoring Progress</h2>
              <div className="bg-white p-6 rounded-lg border border-gray-200">
                <img 
                  src="/dashboard-overview.png" 
                  alt="Sync Status View" 
                  className="rounded-lg mb-4 w-full"
                />
                <p className="text-gray-600 mb-4">
                  Track your sync progress from the dashboard, which shows:
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Total syncs completed</li>
                  <li>• Latest sync status and timestamp</li>
                  <li>• Recent activity history</li>
                </ul>
              </div>
            </section>

            {/* Troubleshooting Section */}
            <section id="troubleshooting" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Troubleshooting</h2>
              <div className="space-y-6">
                <div className="bg-white p-6 rounded-lg border border-gray-200">
                  <h3 className="font-semibold mb-4">Common Issues</h3>
                  <div className="space-y-4">
                    <div>
                      <p className="font-medium text-gray-900">Access Denied Errors</p>
                      <p className="text-gray-600">Verify that the Editor role has been properly assigned in GA4</p>
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">Sync Failures</p>
                      <p className="text-gray-600">Check your BigQuery permissions and schema configuration</p>
                    </div>
                    <div>
                      <p className="font-medium text-gray-900">Data Not Appearing</p>
                      <p className="text-gray-600">Ensure your selected date range contains data in GA4</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Support Section */}
            <section id="support" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Need Help?</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <Mail className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Email Support</h3>
                    </div>
                  <p className="text-gray-600 mb-4">Get in touch with our support team</p>
                  <a href="mailto:support@DataBackfill.com" className="text-blue-600 hover:text-blue-700 font-medium">
                    support@DataBackfill.com
                  </a>
                </div>
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <HelpCircle className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Help Center</h3>
                  </div>
                  <p className="text-gray-600 mb-4">Browse our knowledge base</p>
                  <Link 
                    to="/support" 
                    className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                  >
                    Visit Help Center
                    <ExternalLink size={16} className="ml-1" />
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DocumentationPage;