import React from 'react';
import { Database, Info } from 'lucide-react';

const EnhancedMetricsDashboard = ({ data, paid }) => {
  // If not paid access, show upgrade prompt
  if (!paid) {
    return (
      <div className="bg-white rounded-lg p-6 border border-gray-200 shadow-sm">
        <div className="text-center py-12">
          <Database className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Enhanced Metrics</h3>
          <p className="text-gray-500 mb-4 max-w-md mx-auto">
            Upgrade to access enhanced metrics and dimensions including user demographics, device breakdowns, and conversion analytics.
          </p>
          <button
            onClick={() => window.location.href = '/pricing'}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Upgrade Now
          </button>
        </div>
      </div>
    );
  }

  // Return the simplified dashboard with no charts and no refresh button
  return (
    <div className="space-y-6">
      {/* Header without refresh button */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">BigQuery Data Status</h2>
      </div>

      {/* Simple message instead of charts */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="text-center">
          <Database className="w-12 h-12 mx-auto text-gray-400 mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">Data Successfully Synced</h3>
          <p className="text-gray-600 mb-4">
            Your data has been successfully synced to BigQuery. You can now query it directly 
            from your BigQuery instance using SQL.
          </p>
          <div className="mt-6 p-4 bg-gray-50 rounded-lg text-gray-700 text-sm text-left">
            <p>To access your data in BigQuery:</p>
            <ol className="list-decimal pl-5 mt-2 space-y-2">
              <li>Open the Google Cloud Console</li>
              <li>Navigate to BigQuery</li>
              <li>Select your project and dataset</li>
              <li>Run SQL queries against your synced GA4 data</li>
            </ol>
          </div>
        </div>
      </div>

      {/* GA4 Scoping Information Card */}
      <div className="bg-white rounded-lg border border-gray-200 p-6">
        <div className="flex items-center mb-4">
          <Info className="text-blue-600 mr-2" size={20} />
          <h3 className="text-lg font-medium">Understanding GA4 Data Scoping</h3>
        </div>
        
        <p className="text-gray-600 mb-4">
          When querying your GA4 data in BigQuery, understanding scoping is essential for accurate analysis:
        </p>
        
        <div className="space-y-4">
          <div className="bg-blue-50 p-4 rounded-md">
            <h4 className="font-medium text-blue-800 mb-2">What is GA4 Scoping?</h4>
            <p className="text-sm text-gray-700">
              GA4 data has different levels (user, session, event, item) that determine how metrics and dimensions 
              can be combined without double-counting or creating misleading results.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-gray-50 p-4 rounded-md">
              <h4 className="font-medium text-gray-800 mb-2">Scope Hierarchy</h4>
              <ol className="list-decimal pl-5 text-sm text-gray-700 space-y-1">
                <li><span className="font-medium">User level</span>: Highest level (e.g., userAgeBracket, firstUserSource)</li>
                <li><span className="font-medium">Session level</span>: Mid level (e.g., sessionSource, sessionMedium)</li>
                <li><span className="font-medium">Event level</span>: Lowest level (e.g., eventName, pagePath)</li>
                <li><span className="font-medium">Item level</span>: Special level for ecommerce (e.g., itemName, itemCategory)</li>
              </ol>
            </div>
            
            <div className="bg-gray-50 p-4 rounded-md">
              <h4 className="font-medium text-gray-800 mb-2">Key Scoping Rules</h4>
              <ul className="list-disc pl-5 text-sm text-gray-700 space-y-1">
                <li>Use dimensions at one scope level</li>
                <li>Use metrics at the same or lower scope level than your dimensions</li>
                <li>Mixing session dimensions with user metrics causes double-counting</li>
                <li>Item scope works with item or event metrics</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-yellow-50 p-4 rounded-md text-sm text-yellow-800">
            <h4 className="font-medium mb-2">Common Mistakes to Avoid</h4>
            <ul className="list-disc pl-5 space-y-1">
              <li>Combining session dimensions (sessionSource) with user metrics (totalUsers) causes inflated numbers</li>
              <li>Mixing sourceMedium with channel dimensions creates conflicts</li>
              <li>Using too many dimensions in one query (GA4 API limit is 9)</li>
            </ul>
          </div>
        </div>
        
        <div className="mt-6 text-center">
          <a 
            href="https://support.google.com/analytics/answer/9143382" 
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 font-medium inline-flex items-center"
          >
            Learn more about GA4 dimensions and metrics
            <svg className="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EnhancedMetricsDashboard;